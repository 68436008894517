.inscription {
  display: flex;
  min-height: 100vh;
}

.inscription-content,
.authentification-bird-house {
  width: 50%;
}

/* PARTIE DROITE DE L'INSCRIPTION */
.authentification-bird-house {
  background-color: #fddfcc;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.authentification-bird-house-highlight {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.kiwi,
.Highlight1,
.Highlight2,
.Highlight2 {
  position: absolute;
}

.birdHouse {
  width: 100%;
  top: 30%;
}

/* Définissez l'animation de vol */
@keyframes fly {
  0% {
    transform: translateY(0) translateX(0);
  }
  50% {
    transform: translateY(-20px) translateX(20px);
  }
  100% {
    transform: translateY(0) translateX(0);
  }
}

.kiwi {
  animation: fly 4s infinite linear;
}

@keyframes pong {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
}

.Highlight1 {
  top: 10%;
  right: 10%;
  animation: pong 1s infinite;
}

.Highlight2 {
  top: 40%;
  left: 10%;
  animation: pong 2s infinite;
}

.Highlight3 {
  top: 74%;
  left: 10%;
}

/* PARTIE GAUCHE DE L'INSCRIPTION */

.inscription-content {
  padding: 2% 7%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.inscription-content > .logo {
  width: 7vw;
}

.inscription-content > h1 {
  color: #2b2b2b;

  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.inscription-content > h1 span {
  background: linear-gradient(180deg, #858585 0%, #f75d00 100%);
  background-clip: text;
  -webkit-background-clip: text;
}

.inscription-content > p {
  color: #555;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.inscription-form {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding-block: 5%;
}

.inscription-form input {
  padding: 2% 3%;
}

.ou-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.line {
  flex: 1;
  height: 1px;
  background: rgba(204, 204, 204, 0.6);
}

.or {
  margin: 0 20px;
  color: #555;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.label {
  color: #2b2b2b;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.select-ville {
  outline: none;
  padding: 2% 3%;
  gap: 12px;
  align-self: stretch;
  width: 100%;
  margin-top: 1%;
}

.input-password {
  padding-right: 3%;
}

.input-email {
  padding-left: 3%;
}

.input-password svg:hover {
  fill: #000;
}

.continuer-inscription {
  display: flex;
  width: 100%;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  background: linear-gradient(114deg, #f40000 0%, #f75000 100%);
  color: #fff;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.inscription-compte {
  display: flex;
  gap: 10px;
  color: var(--neutral-neutral-300, #555);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
}

.inscription-compte > a {
  margin: 0 !important;
}
