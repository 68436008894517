.motDePasseOublie {
  display: flex;
  height: 100vh;
}

.motDePasseOublie-content {
  padding: 7% 7%;
  width: 50%;
  display: flex;
  flex-direction: column;
}
.motDePasseOublie-content > .logo {
  width: 7vw;
}

.motDePasseOublie-content > h1 {
  color: #2b2b2b;

  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20%;
}

.motDePasseOublie-content > h1 > span {
  color: #f75d00;
}

.motDePasseOublie-content > p {
  color: #555;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.motDePasseOublie-form {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding-block: 5%;
}

.motDePasseOublie-form input {
  padding: 2% 3%;
}

@media (max-width: 1024px) {
  .motDePasseOublie-content {
    width: 100%;
    justify-content: center;
  }
}
