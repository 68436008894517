@media screen and (min-width: 768px) and (max-width: 1024px) {
  .middle-section-container {
    flex-direction: column;
    align-items: center;
  }

  .introductionBG {
    height: 70vh;
  }
  .middle-section-container-left,
  .middle-section-container-right {
    width: 100%;
  }

  .middle-section-container-right {
    padding: 10% 5% 10% 5%;
    align-items: center;
    text-align: center;
  }
  .introduction-container {
    top: 50%;
    left: 50%;
    width: 75%;
    transform: translate(-50%, -50%);
    text-align: center; /* Centrer le texte à l'intérieur de l'élément */
    line-height: 100px; /* Centrer verticalement le texte */
  }
  .bulleDroiteOiseauRougeContainer {
    display: none;
  }
  .partie-jaune {
    height: 90vh;
  }
}
