/* ------------------------ INTRODUCTION ------------------------ */
.introduction {
  position: relative;
  overflow: hidden;
}
.bulleGauche {
  position: absolute;
  animation: moveAnimation 3s infinite alternate ease-in-out;
}

.bulleGauche > svg {
  width: 30%;
}

.introductionBG > svg {
  height: 100%;
}

.bulleDroiteOiseauRougeContainer {
  position: absolute;
  overflow: hidden;
  top: 0%;
  width: 50%;
  height: 100%;
  right: 0%;
  display: flex;
  align-items: end;
  justify-items: end;
}
.bulleDroite {
  position: absolute;
  left: 40%;
  transform: translate(-50%, -50%);
  animation: moveAnimation 5s infinite alternate ease-in-out;
}

.bulleDroite > svg {
  width: 35vw;
}

@keyframes moveAnimation {
  0% {
    transform: translate(2%, -8%);
  }
  100% {
    transform: translate(-3%, -7%);
  }
}

.oiseauRouge {
  position: absolute;
  top: 50%; /* Alignement vertical au milieu */
  left: 70%; /* Alignement horizontal au milieu */
  transform: translate(-50%, -50%);
  z-index: 1;
}

.introduction-container {
  position: absolute;
  top: 25%;
  left: 8%;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.introduction-container h1 {
  color: #2b2b2b;

  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.introduction-container p {
  color: #555;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.introduction-container button {
  width: min-content;
}

.introduction-container > h1 span {
  color: #f75d00;
}

/* ------------------------ PART 2 HOME PAGE ------------------------ */
.part2HomePage {
  position: relative;
  display: flex;
  margin-block: 3%;
  padding-inline: 8%;
  gap: 10rem;
}

.part2HomePage-gauche {
  width: 65%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.part2HomePage-gauche > section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 20px;
  padding: 2% 4%;

  transition: box-shadow 0.3s ease; /* Animation de transition */
}

.part2HomePage-gauche h3 {
  color: #2b2b2b;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.part2HomePage-gauche button {
  width: fit-content;
  margin-left: auto;
}

.section-text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 20px;
  padding: 5%;
}

.section-text h3 {
  color: #2b2b2b;

  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.section-text p {
  color: #555;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.part2HomePage-droite {
  width: 35%;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.part2HomePage-droite .oeuf {
  position: absolute;
}

/* ------------------------ DESCRIPTION HOME PAGE ------------------------ */

.partie-jaune {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vw;
}

.formes-partie-jaune {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.formes-partie-jaune > svg {
  animation: moveAnimation 5s infinite alternate ease-in-out;
  width: 6%;
}

.formes-partie-jaune > svg:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
}

.formes-partie-jaune > svg:nth-child(2) {
  position: absolute;
  bottom: 10%;
  right: 0;
}

.description-homepage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 5rem;
  justify-content: center;
}

.description-homepage h3 {
  color: #2b2b2b;

  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.description-homepage img {
  width: 25%;
  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.2)); /* Effet de drop-shadow */
}

.description-homepage-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
  max-width: 50%;
}

.description-homepage-part {
  display: flex;
  border-radius: 20px;

  /* Animation de transition */
}

.description-homepage-part:nth-child(odd) {
  flex-direction: row;
}

.description-homepage-part:nth-child(even) {
  flex-direction: row-reverse;
}

/* ------------------------ROADMAP ------------------------ */

.roadmap {
  display: flex;
  justify-content: space-between;
  margin-block: 3rem;
  max-width: 75%;
  margin: auto;
  margin-block: 3%;
  margin-top: 70px;
}

.roadmap-item {
  flex: 1;
  padding: 1%;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 2px 2px 1px rgba(151, 131, 131, 0.33);
  margin-inline: 2rem;
  border-radius: 30px;
}

.roadmap-item div:nth-child(1) {
  max-width: 50%;
}

.roadmap-content p {
  font-size: 16px;
  font-weight: bold;
}

.roadmap-content span {
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  margin-top: 10px;
}

.roadmap-item:hover .roadmap-content span {
  background-color: #0056b3;
}

/* INSCRIPTION VISITEUR  */
.introduction-inscrit-visiteur {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
}

.introduction-inscrit-visiteur img {
  width: 100%;
  min-height: 280px;
}

.introduction-inscrit-visiteur .introduction-inscrit-visiteur-content {
  border-radius: 10px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  display: flex;
  flex-direction: column;
  gap: 1em;
  height: max-content;
  left: 50%;
  color: white;
}

.introduction-inscrit-visiteur h1,
.introduction-inscrit-visiteur h3,
.introduction-inscrit-visiteur a {
  margin: 0% 0;
}

.introduction-inscrit-visiteur a {
  padding: 2% 10%;
  border-radius: 10px;
  text-decoration: none;
  font-weight: bold;
  width: fit-content;
  margin: auto;
  margin-top: 10%;
}

/* INSCRITPION SIMPLE PAS ENCORE VISITEUR  */
.introduction-inscrit {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  padding-bottom: 10vw;
}

.introduction-inscrit img {
  width: 100%;
  min-height: 350px;
  max-height: 600px;
}

.introduction-inscrit .introduction-inscrit-content {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 10px;
  display: grid;

  grid-template-columns: repeat(2, 1fr);
  gap: 10%;
  padding-top: 20px;

  z-index: 10;
}

.introduction-inscrit-card {
  background: #ffffffd0;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 10%;
  text-align: left;
  display: flex;
  gap: 2em;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
}

.introduction-inscrit h1 {
  font-size: 2em;
  font-weight: 600;
}

.introduction-inscrit h3 {
  font-size: 1.2em;
}

.introduction-inscrit a {
  background-color: #f75d00;
  color: white;
  padding: 2% 10%;
  border-radius: 15px;
  text-decoration: none;
  font-weight: bold;
  width: fit-content;
  margin-top: 10%;
  transition: background-color 0.3s ease;
}

.introduction-inscrit a:hover {
  background-color: #e55000;
}
