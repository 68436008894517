.boiteAuxLettres-menu-item {
  display: flex;
  height: 48px;
  padding: 12px 20px;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.boiteAuxLettres-menu-item-active {
  border-radius: 8px;
  background: var(--yellow-yellow-500, #ffc535);
}

.list-message {
  margin-top: 3%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.message {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 12px;
  border: 1px solid rgba(204, 204, 204, 0.6);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.message-titre {
  color: var(--neutral-neutral-400, #2b2b2b);
  /* Semibold/Text M */

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.message-content {
  display: flex;
  align-items: center;
  gap: 2rem;
  text-align: start;
}

.message-content div {
  width: auto;
}

.message-content img {
  width: 5vw;
  height: 5vw;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 2px solid #fff;
}

.message-content h4 {
  color: var(--neutral-neutral-400, #2b2b2b);
  /* Semibold/Text L */

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.message-content p {
  color: var(--neutral-neutral-300, #555);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.message-content-user-info {
  display: flex;
  gap: 0.5rem;
}
