/* ------------------------ PROGRAMMATION VISITE ------------------------ */
.programmation-visite {
  padding-inline: 15%;
  padding-top: 10%;
  padding-bottom: 3%;
  display: flex;
  flex-direction: column;
  gap: 5rem;
}
.programmation-visite-titre-container {
  position: relative;
}

.programmation-visite-titre-container svg {
  position: absolute;
  top: 40%;
  right: 20%;
}
.programmation-visite-titre {
  color: var(--neutral-neutral-400, #2b2b2b);
  text-align: center;
  width: 60%;
  margin: auto;

  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.programmation-visite-sous-titre {
  color: var(--neutral-neutral-400, #2b2b2b);

  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.programmation-visite-sous-titre-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* ------------ PART CONTENT -------------- */
.part-content {
  margin-top: 3%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.part-content > div {
  width: 50%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.part-content1 {
  margin-top: 3%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.part-content1 > div {
  width: 50%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  .part-content {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    margin-top: 0%;
  }

  .part-content > div {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 0rem;
  }

  .programmation-visite-sous-titre {
    margin-bottom: 1rem;
  }
}

/* -------------- SELECT PROFILE PART 3 -----------------*/
.select-liste-profil {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 3%;
}

.select-profil {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 3% 3%;
  border-radius: 8px;
  border: 1px solid rgba(204, 204, 204, 0.6);
  cursor: pointer;

  height: 100px;
  transition: border-color 0.3s, background-color 0.3s; /* Transition animée */
}

.select-profil > div {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-left: 3%;
  height: 100%;
}

.select-profil > div > img {
  height: 100%;
  width: 5vw;
  height: 5vw;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 2px solid #fff;
}

.select-profil:hover {
  border: 1px solid var(--yellow-yellow-500, #ffc535);
  background: var(--yellow-yellow-50, #fff3d7);
}

.select-profil-actif {
  border: 1px solid var(--yellow-yellow-500, #ffc535);
  background: var(--yellow-yellow-50, #fff3d7);
}

.profil-info {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.profil-info h3 {
  color: var(--neutral-neutral-400, #2b2b2b);
  /* Medium/Text XL */

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.profil-info p {
  color: var(--neutral-neutral-300, #555);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.profil-rate {
  display: flex;
  color: var(--neutral-neutral-400, #2b2b2b);
  /* Medium/Text L */

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.profil-price {
  color: var(--neutral-neutral-400, #2b2b2b);
  text-align: right;
  /* Bold/Display XS */

  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* -------------------- RECAP PROGRAMMATION VISITE -----------------------*/
.recap-programmation-visite {
  display: flex;
  justify-content: space-between;
  padding-top: 5%;
  gap: 3rem;
}

.recap-programmation-visite > p {
  padding-top: 5%;
}

.recap-programmation-visite-gauche {
  display: flex;
  padding: 5% 3%;
  width: 80%;
  height: fit-content;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
  border-radius: 8px;
  border: 1px solid rgba(204, 204, 204, 0.6);
  background: var(--White, #fff);
}

.recap-programmation-visite h4 {
  color: var(--neutral-neutral-400, #2b2b2b);
  /* Semibold/Text XL */

  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.recap-programmation-visite h2 {
  /* Bold/Display S */

  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: var(--gradients-gradient-2, linear-gradient(180deg, #858585 0%, #f75d00 100%));
  background-clip: text;
  -webkit-background-clip: text;
}

.recap-programmation-profil {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
  color: var(--neutral-neutral-400, #2b2b2b);
  /* Medium/Text XL */

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.recap-programmation-profil span {
  color: var(--neutral-neutral-200, #808080);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.recap-programmation-profil img {
  height: 100%;
  width: 7vw;
  height: 7vw;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 2px solid #fff;
}

.recap-programmation-profil-name {
  display: flex;
  gap: 0.2em;
  font-weight: bold;
  margin-bottom: 5%;
}
.recap-programmation-infos {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.recap-programmation-infos div {
  display: flex;
  gap: 1rem;
  color: var(--neutral-neutral-300, #555);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.recap-programmation-autre {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.recap-programmation-autre textarea {
  color: var(--neutral-neutral-300, #555);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  border: 1px solid rgba(0, 0, 0, 0.149);
  border-radius: 10px;
  height: 10vw;
  padding: 3%;
  outline: none;
}

.recap-programmation-droite {
  display: flex;
  flex-direction: column;
}

.recap-programmation-date {
  display: flex;
  align-items: center;
  width: 26%;
  border-radius: 8px;
  background: var(--yellow-yellow-50, #fff3d7);
  width: 100%;
  padding: 10%;
  margin-bottom: 10%;
}

.recap-programmation-date img {
  width: 30%;
  height: auto;
}

.recap-programmation-prix {
  display: flex;
  width: 100%;
  padding: 7%;
  flex-direction: column;
  gap: 28px;
  border-radius: 8px;
  border: 1px solid rgba(204, 204, 204, 0.6);
  background: var(--White, #fff);
}

.recap-programmation-prix > div p {
  color: var(--neutral-neutral-300, #555);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.recap-programmation-prix-total {
  color: var(--neutral-neutral-300, #555);

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
}

.recap-programmation-prix-total h2 {
  color: var(--neutral-neutral-400, #2b2b2b);
  /* Bold/Display XS */

  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.recap-programmation-texte {
  margin-top: 2%;
  width: 80%;
}

/* --------------------------- DISPLAY CHICKEN ---------------------------*/
.chicken-message {
}

/* Styles pour .chicken-message-filtre lorsque le message est affiché */
.chicken-message-filtre {
  height: 100vh;
  width: 100vw;
  position: fixed; /* Utilisation de 'fixed' pour éviter le défilement de la page principale */
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Empêche le défilement de la page principale */
}

/* Styles pour .chicken-message-content */
.chicken-message-content {
  display: inline-flex;
  padding: 40px 32px 44px 32px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 30%;
  border-radius: 12px;
  background: var(--yellow-yellow-50, #fff3d7);
  height: fit-content;
}
