html,
body,
* {
  font-family: Inter;
}
h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

p {
  font-size: 1rem;
}
.btn {
  margin-top: 2.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
}

.btn-orange {
  cursor: pointer;
  background-color: #ff9900;
  color: #ffffff;

  &:hover {
    background-color: #ff8000;
  }
}

input,
textarea {
  outline: none;
}

.btn-noir {
  background-color: #000;
  color: #ffffff;

  &:hover {
    background-color: #222222;
  }
}

hr {
  height: 1px;
  width: 100%;
  background: rgba(204, 204, 204, 0.6);
}

.position-absolute {
  position: absolute;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
  }
}
