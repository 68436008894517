@media screen and (max-width: 760px) {
  /* ------------------------ PROGRAMMATION VISITE ------------------------ */
  .programmation-visite {
    padding-inline: 5%;
    padding-top: 25%;
    padding-bottom: 3%;
    display: flex;
    flex-direction: column;
    gap: 5rem;
  }
  .programmation-visite-titre-container {
    position: relative;
  }

  .programmation-visite-titre-container svg {
    top: 70%;
  }
  .programmation-visite-titre {
    width: 100%;
    font-size: 34px;

    font-weight: 700;
    line-height: normal;
  }

  /* -------------------- RECAP PROGRAMMATION VISITE -----------------------*/
  .recap-programmation-visite {
    flex-direction: column;
  }

  .recap-programmation-visite-gauche {
    width: 100%;
  }

  .chicken-message-content {
    width: 90%;
  }
}
