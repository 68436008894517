/* 📱 Mobile (moins de 600px) */
@media (max-width: 600px) {
    .modal-content {
        width: 100%;
        max-width: 90%;
        padding: 15px;
    }

    .modal-content button {
        width: 100%; /* Le bouton prend toute la largeur sur mobile */
        font-size: 14px;
    }
}