.alice-carousel__wrapper {
  width: 80%;
  margin: auto;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  position: absolute;
  width: fit-content;
  width: 5%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  top: 50%;
}
.alice-carousel__prev-btn {
  left: 5%;
}
.alice-carousel__next-btn {
  right: 0%;
}

.date-day {
  color: var(--neutral-neutral-400, #2b2b2b);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

/* Styles pour le numéro du jour */
.date-number {
  color: var(--neutral-neutral-400, #2b2b2b);

  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.date-month {
  color: var(--neutral-neutral-400, #2b2b2b);
  /* Regular/Text S */

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
