@media screen and (max-width: 768px) {
  .profil-visiteur {
    width: 90%;
    margin: auto;
    flex-direction: column;
  }
  .infos-user {
    width: 100%;
  }
  .infos-user-container {
    width: 100%;
  }

  .profil-visiteur-partDroite {
    width: 100%;
    margin-top: 10%;
  }

  .calendrier-disponibilte {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 5%;
    text-align: center;
  }
  .commentaire-utilisateur {
    flex-direction: column;
    border: 1px solid rgb(168, 167, 167);
    border-radius: 10px;
    padding: 5%;
  }
  .commentaire-utilisateur-container {
    width: 100%;
    margin-bottom: 5%;
  }
  .commentaire-utilisateur > p {
    width: 100%;
  }
}
