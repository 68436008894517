.dashboard {
  display: flex;
  background: var(--willpower-orange-willpower-orange-50, #fddfcc);
  min-height: 100vh;
}

.dashboard-menu {
  width: 19%;
  padding-top: 2%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.dashboard-menu > div:nth-child(1) {
  display: none;
}

.dashboard-menu > .logout {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 5%;
  border-top: 1px solid black;
  padding-top: 5%;
  margin-bottom: 5%;
  width: 90%;
  cursor: pointer;

  transition: background-color 0.3s;
}

.logout img {
  width: 3vw;
  height: 3vw;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 2px solid #fff;
}

.logout .name {
  /* Semibold/Text S */

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.logout .email {
  color: var(--neutral-neutral-300, #555);
  /* Regular/Text S */

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dashboard-menu > a {
  width: 50%;
  margin-inline: auto;
}

.dashboard-menu-content {
  margin-top: 15%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.dashboard-menu-content li {
  display: flex;
  gap: 1rem;
  align-items: center;
  border-radius: 8px;
  padding: 12px;
  align-self: stretch;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: #2b2b2b;
  font-weight: 500;
  line-height: normal;
}

.dashboard-menu-content li:hover {
  background: #ff1e00
}

.dashboard-menu-content li:hover span {
  color: white;
  font-weight: 600;
}

.dashboard-menu-content li:hover svg {
  fill: white;
}

.activeMenu {
  background: #ff1e00
}
.activeMenu span {
  color: white;
  font-weight: 600;
}

.activeMenu svg {
  fill: white;
}

.dashboard-content {
  width: 81vw;
  margin-top: 2%;
  padding: 5%;
  border-radius: 40px 0px 0px 0px;
  background: var(--White, #fff);
}

.dashboard-content-header-mobile {
  display: none;
}
.dashboard-content-text {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-bottom: 3%;
}

.dashboard-content .info-calendrier {
  color: var(--neutral-neutral-400, #2b2b2b);
  /* Medium/Text M */

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* ------------------- PARAMETRES -------------- */
.select-fuseau {
  width: 100%;
}

.desactiver-compte {
  margin-top: 5%;
  /* Semibold/Text M */

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: var(--gradients-gradient-1, linear-gradient(114deg, #f40000 0%, #f75000 100%));
  background-clip: text;
  -webkit-background-clip: text;
}
/* ------------------- SWITCH TOGGLE -------------- */
.switch-container {
  display: flex;
  justify-content: space-between;
  margin-block: 2%;
}
.switch {
  position: relative;
  display: inline-block;
  width: 47px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background: linear-gradient(114deg, #f75000 0%, #f40000 100%);
}

input:focus + .slider {
  box-shadow: 0 0 1px #f75000;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media (max-width: 1024px) {
  .dashboard {
    flex-direction: column;
  }

  .dashboard-menu {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .dashboard-content {
    width: 100vw;
    margin-top: 2%;
    padding: 5%;
    border-radius: 40px 0px 40px 0px;
    background: var(--White, #fff);
  }

  .dashboard-menu > .logout {
    width: auto;
    margin: auto;
    display: flex;
    align-items: center;
    gap: 2rem;
    padding-inline: 0%;
    border-top: 0px;
    padding-top: 0%;
    margin-bottom: none;
    border-left: 1px black solid;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .dashboard-menu > .logout > p {
    font-size: 0;
  }

  .dashboard-menu-content {
    margin-top: 0%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-right: 1rem;
    width: auto;
  }

  .dashboard-menu-content li {
    padding: 5px 5px 5px 5px;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }
  .dashboard-menu-content li span {
    display: none;
  }
  .logo {
    width: 60px;
    height: 60px;
  }
}
