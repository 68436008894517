/* NotFound.css */
.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.message {
  text-align: center;
  padding: 20px;
}

.message h1 {
  font-size: 3rem;
  color: #ff5733;
}

.message p {
  font-size: 1.2rem;
}

.fun-image img {
  max-width: 100%;
  height: auto;
}
