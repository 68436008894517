@media screen and (min-width: 768px) and (max-width: 1024px) {
  .profil-visiteur {
    width: 80%;
    margin: auto;
    flex-direction: column;
  }
  .infos-user {
    width: 100%;
  }
  .infos-user-container {
    width: 100%;
  }

  .profil-visiteur-partDroite {
    width: 100%;
    margin-top: 10%;
  }

  .calendrier-disponibilte {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 5%;
  }
}
