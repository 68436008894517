.menu {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-self: center; /* Ajustement de l'alignement à droite */
}
.menu ul {
  display: flex;
  gap: 3rem;
  list-style: none;
  align-items: center;
}

.user-picture img {
  width: 50px;
  object-fit: cover;
  height: 50px; /* Ajustement de la taille de l'image */
  border-radius: 50%;
  border: 2px solid #fff;
  cursor: pointer; /* Curseur au survol */
  transition: border 0.3s ease; /* Animation de transition */
}

.user-picture img:hover {
  border: 2px solid #e78341; /* Couleur de bordure au survol */
}

.user-picture-content {
  position: relative;
}

.user-picture-info {
  margin-top: 5%;
  position: absolute;
  top: 100%;
  left: 50%; /* Centrer horizontalement par rapport à l'image */
  transform: translateX(-50%); /* Ajuster pour centrer horizontalement */
  background: #fff;
  padding: 20%;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: 0.5s ease;
  width: max-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  gap: 2rem;
}

.hidden {
  display: none;
}

.menu-li-hover {
  position: relative;
  color: inherit;
  padding-top: 10px;
  text-decoration: none;
}

.menu-li-hover:before,
.menu-li-hover:after {
  content: '';
  position: absolute;
  transition: transform 0.5s ease;
}
.menu-li-hover:before {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  margin-bottom: -10px;
  background: #f75d00;
  transform: scaleX(0);
}
.menu-li-hover:hover:before {
  transform: scaleX(1);
}

@media (max-width: 768px) {
  .header {
    height: fit-content;
    flex-direction: column;
  }

  .header-hamburger {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-hamburger .logo > img {
    margin: auto;
    width: auto;
  }

  .header .menu li {
    border: 1px solid black;
    width: 100%;
    text-align: center;
    border-radius: 10px;
    padding: 2% 0% 2% 0%;
    margin-block: 1%;
  }

  .header .menu li:first-child {
    margin-top: 5%;
  }
  .header .menu li:last-child {
    border: none;
    width: 100%;
  }

  .header .menu li:last-child button {
    width: 100%;
  }
  .header .menu {
    flex-direction: column;
  }

  /* menu icon */

  .header .menu-icon {
    cursor: pointer;
    display: inline-block;
    padding: 5% 2%;
    position: relative;
    user-select: none;
  }

  .header .menu-icon .navicon {
    background: #333333;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 18px;
  }

  .header .menu-icon .navicon:before,
  .header .menu-icon .navicon:after {
    background: #333;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
  }

  .header .menu-icon .navicon:before {
    top: 5px;
  }

  .header .menu-icon .navicon:after {
    top: -5px;
  }

  /* menu btn */

  .header .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
  }

  .header .menu-btn:checked ~ .menu-icon .navicon {
    background-color: transparent;
  }

  .header.active {
    background-color: white;
  }

  .header .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
  }

  .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
  .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
  }

  .menu {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }

  .menu.open {
    max-height: 700px;
    transition: max-height 0.3s ease-in-out;
    display: initial;
  }
}
