.validationCompte {
  display: flex;
  height: 100vh;
}

.validationCompte-content {
  padding: 0% 25%;
  display: flex;
  text-align: center;
  gap: 2rem;
  flex-direction: column;
  justify-content: center;
}

.validationCompte-content > h1 {
  color: #2b2b2b;

  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.validationCompte-content > p {
  color: #555;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

@media (max-width: 1024px) {
  .validationCompte-content {
    padding: 0% 5%;
  }
}
