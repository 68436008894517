.chat {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  justify-content: space-between;
  border: 1px solid rgba(204, 204, 204, 0.6);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  min-height: 495px;
}

.message__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3% 5%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.message__header-img-profil {
  position: relative;
}
.message__header-img-profil > img {
  position: relative;
  transition: background-color 0.3s, color 0.3s;
  background-color: #ffffff;
  width: 50px; /* Largeur fixe (ajustez selon vos besoins) */
  height: 50px; /* Hauteur fixe (ajustez selon vos besoins) */
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  padding: 1px;
  object-fit: cover; /* Assure que l'image est bien ajustée dans le cercle */
}

.round-user-Login {
  background-color: green;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  top: -5%;
  right: -2%;
  z-index: 2;
  border: 2px solid white;
}

.message__header > svg {
  transition: background-color 0.3s, color 0.3s;
  background-color: #ffffff;
  color: #ff0000;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  padding: 8px; /* Espacement ajouté autour de l'icône */
  font-size: 30px;
}

.message__header > svg:hover {
  background-color: #ff0000;
  color: #ffffff;
  border-color: #ff0000;
  fill: white;
}

.message__header > svg:hover {
  background-color: #ff0000;
  color: #ffffff;
  border-color: #ff0000;
  fill: white;
}

.message__recipient {
  margin-top: 1%;
  background-color: #f6b4a3;
  width: 300px;
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
}
.message__sender {
  margin-top: 1%;
  background-color: rgb(194, 243, 194);
  max-width: 300px;
  padding: 10px;
  border-radius: 10px;
  margin-left: auto;
  font-size: 15px;
  height: auto;
}
.message__container {
  margin-block: 3%;
  min-height: 300px;

  width: 100%;
  padding: 20px;
  overflow-y: auto; /* Utilisez "auto" pour activer la barre de défilement si nécessaire */
  height: 100%; /* Réglez la hauteur maximale, ajustez-la selon vos besoins */
}
.message__chats > p {
  font-size: 13px;
}
.sender__name {
  text-align: right;
}
.message__status {
  position: fixed;
  bottom: 50px;
  font-size: 13px;
  font-style: italic;
}

.chat__footer {
  background-color: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e0e0e0;
}

.chat__footer_form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.chat__footer_form > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat__footer .message {
  flex-grow: 1;
  height: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px;
  outline: none;
}

.chat__footer .emojiBtn {
  cursor: pointer;
  font-size: 24px;
  margin-right: 10px;
  color: #ccc;
  transition: color 0.3s;
}

.chat__footer .emojiBtn:hover {
  color: #ffbb33;
}

.chat__footer .sendBtn {
  min-width: 120px;
  background-color: #ffbb33;
  padding: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.chat__footer .sendBtn:hover {
  background-color: #ffaa00;
}

.chat__footer .sendBtn .send-icon {
  margin-right: 8px;
  font-size: 20px;
}

/* Styles for emoji picker if it requires additional styling */

.emoji-picker {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  bottom: 50px;
  right: 20px;
  z-index: 1;
}

.EmojiPickerReact {
  position: absolute !important;
  bottom: 10%;
  right: 5%;
  width: 25% !important;
}
