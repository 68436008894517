@tailwind base;
@tailwind components;
@tailwind utilities;

.debug {
  border: 2px solid black;
}

.debug-yellow {
  border: 2px solid rgb(229, 255, 0);
}

.text-color-orange {
  color: #f8891e;
}

.bg-color-orange {
  color: #f8891e;
}

button {
  cursor: pointer;
  display: flex;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none !important;
}

.bg-tranparent {
  background: var(--gradients-gradient-1, linear-gradient(114deg, #f40000 0%, #f75000 100%));
  background-clip: text;
  -webkit-background-clip: text;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid var(--gradients-gradient-1, #f40000);
}

.bg-orange {
  cursor: pointer;
  background: linear-gradient(114deg, #f40000 0%, #f75000 100%);
  color: #fff;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.bg-orange::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(114deg, #f75000 0%, #f40000 100%);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.bg-orange:hover::before {
  opacity: 1;
}
.select {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid rgba(204, 204, 204, 0.6);
  color: black;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

select:focus {
  outline: none;
}
.input {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid rgba(204, 204, 204, 0.6);
  width: 100%;
  padding: 0.5% 3%;
  padding-right: 3%;
  position: relative;
}

.input input {
  border: none;
  margin: 0;
  outline: none;
}

.input svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
  fill: #808080;
}
/* Ajoutez ces règles CSS dans votre fichier de styles (par exemple, styles.css) */

/* Pour les écrans mobiles (jusqu'à 640px de large) */
@media screen and (max-width: 640px) {
  .absolute.inset-y-0.right-0 {
    /* Redéfinissez la position du texte de droite */
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    transform: none;
  }

  /* Ajustez la mise en page de la section de texte avec image */
  .flex.flex-col.justify-center.text-right.items-end {
    text-align: center;
    align-items: center;
  }
}

/* Pour les écrans tablettes (641px à 1024px de large) */
@media screen and (min-width: 641px) and (max-width: 1024px) {
  /* Ajoutez des règles CSS spécifiques aux tablettes si nécessaire */
}

.scrollbar-review {
  /* Indique que les styles sont appliqués à la barre de défilement */
  scrollbar-width: thin;
  scrollbar-color: #b3b3b3 #f0f0f0; /* Couleur de la barre de défilement et de son curseur */
}

.scrollbar::-webkit-scrollbar {
  /* Largeur de la barre de défilement */
  width: 8px;
}

.scrollbar::-webkit-scrollbar-thumb {
  /* Style du curseur de la barre de défilement */
  background-color: #b3b3b3; /* Couleur du curseur */
  border-radius: 4px; /* Bord arrondi du curseur */
}
