/* ------------------------ INTRODUCTION ------------------------ */
.profil-visiteur {
  display: flex;
  padding-block: 3%;
  padding-top: 15vh;
}

.infos-user {
  width: 35%;
  display: flex;
  justify-content: center;
}

.userCircle {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.userCircle-img {
  position: relative;
  width: 100%;
  max-width: 200px;
  aspect-ratio: 1;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.userCircle-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.userCircle-img span {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  opacity: 0;
  pointer-events: none;
}

.userCircle-img.hovered span {
  opacity: 1;
  pointer-events: auto;
}

.notes-avis {
  display: flex;
  justify-content: center;
  align-items: center;
}

.infos-reponses {
  width: 100%;
}
.infos-reponses > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4%;
  margin-bottom: 5%;
  border-radius: 8px;
  background: #f4f5f6;
}

.profil-visiteur-partDroite {
  width: 65%;
  padding-right: 8%;
}

.calendrier-disponibilte h2 {
  color: var(--neutral-neutral-400, #2b2b2b);
  /* Semibold/Display XS */

  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.calendrier-disponibilte p {
  color: var(--neutral-neutral-300, #555);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.commentaires-utilisateurs-header {
  display: flex;
  justify-content: space-between;
  padding-top: 5%;
  align-items: center;
  gap: 1rem;
}

.commentaires-utilisateurs-header > h2 {
  color: var(--neutral-neutral-400, #2b2b2b);
  /* Semibold/Display XS */

  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.commentaires-utilisateurs-header > select {
  border-radius: 8px;
  border: 1px solid rgba(204, 204, 204, 0.6);
  display: flex;
  padding: 10px 16px;
  align-items: center;
  gap: 12px;
  color: var(--neutral-neutral-400, #2b2b2b);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.commentaire-utilisateur {
  display: flex;
  margin-top: 5%;
  align-items: center;
  padding: 1%;
  border-radius: 20px;

  box-shadow: 3px 3px 5px 4px rgba(0, 0, 0, 0.05);
}

.commentaire-utilisateur-container {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.2em;
}

.commentaire-utilisateur-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.commentaire-utilisateur-info img {
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.commentaire-utilisateur-info-stars {
  display: flex;
  width: 50%;
  gap: 0.5rem;
  margin-top: 3%;
}

.commentaire-utilisateur-info h3 {
  color: var(--neutral-neutral-400, #2b2b2b);
  /* Semibold/Text L */

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.commentaire-utilisateur-info p {
  color: var(--neutral-neutral-300, #555);
  /* Regular/Text M */

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.commentaire-utilisateur > p {
  width: 65%;
  color: var(--neutral-neutral-300, #555);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

/* Ajoutez ces styles à votre fichier CSS existant */

.menu-toggle {
  display: none; /* Masquer le menu hamburger par défaut */
  cursor: pointer;
}

@media (max-width: 768px) {
  .menu {
    flex-direction: column;
    align-items: flex-start;
  }

  .menu-toggle {
    display: block; /* Afficher le menu hamburger sur les tablettes et les mobiles */
    margin-right: 1rem;
    font-size: 24px;
  }

  .menu-items {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #ffffff;
    padding: 1rem;
    transition: transform 0.3s ease-in-out;
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
  }

  .menu-items.open {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
}
