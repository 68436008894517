@media screen and (max-width: 768px) {
  /* ------------------------ INTRODUCTION ------------------------ */

  .introductionBG,
  .introductionBG > svg {
    height: 100vh;
  }

  .middle-section-container {
    flex-direction: column;
    align-items: center;
  }

  .middle-section-container-left,
  .middle-section-container-right {
    width: 100%;
  }

  .bulleDroiteOiseauRougeContainer {
    display: none;
  }

  .middle-section-container-right {
    padding: 10% 5% 10% 5%;
    align-items: center;
    text-align: center;
  }

  .description-homepage-part {
    flex-direction: column;
  }

  .description-homepage-part:nth-child(odd),
  .description-homepage-part:nth-child(even) {
    flex-direction: column;
  }

  .formes-partie-jaune {
    display: none;
  }

  .part2HomePage {
    flex-direction: column-reverse;
    padding: 0;
    gap: 1em;
  }

  .part2HomePage-gauche {
    width: 100%;
  }

  .part2HomePage-droite {
    width: 75%;
    margin: auto;
    margin-top: 3%;
  }

  .part2HomePage-gauche > section {
    width: 90%;
    margin: auto;
    gap: 2em;
    text-align: center;
  }

  .part2HomePage-gauche button {
    margin: auto;
  }

  .part2HomePage-gauche h3 {
    color: #2b2b2b;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .section-text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 20px;
    padding: 5%;
    font-size: 14px;
  }

  .section-text h3 {
    color: #2b2b2b;

    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .maison-perroquet {
    display: none;
  }

  /* ------------------------ INTRODUCTION ------------------------ */

  /* Pour éliminer la bulle de gauche sur les écrans étroits */
  .bulleGauche {
    display: none;
  }

  /* Réduire la taille de la bulle droite */
  .bulleDroite {
    left: 30%;
  }

  .introduction-container {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-align: center;
  }

  .introduction-container > h1 {
    text-align: center;
  }

  /* ------------------------ DESCRIPTION HOME PAGE ------------------------ */

  .partie-jaune {
    align-items: flex-start; /* Aligner le contenu en haut */
    height: 100%;
  }
  .introduction-inscrit {
    display: block;
    padding-bottom: 0px;
    max-height: 700px;
  }
  .introduction-inscrit .introduction-inscrit-content {
    display: grid;

    grid-template-columns: repeat(1, 2fr);
    position: relative;

    gap: 5%;
    padding-top: 0px;
    width: 100%;
  }

  .introduction-inscrit-card {
    background: #ffffffd0;
    border-radius: 10px;
    padding: 5%;
    display: flex;
    gap: 2em;
    justify-content: center;
  }

  .introduction-inscrit h1 {
    font-size: 1.2em;
    font-weight: 700;
  }

  .introduction-inscrit h3 {
    font-size: 0.8em;
  }

  .partie-jaune svg,
  .partie-jaune img {
    display: none;
  }

  .description-homepage {
    position: static; /* Supprimer la position absolue */
    padding: 0%; /* Ajuster les marges horizontales */
    text-align: center; /* Centrer le texte */
  }

  .description-homepage-container {
    flex-direction: column; /* Passer à une disposition en colonnes */
    gap: 2rem;
    max-width: 90%;
  }

  .description-homepage-part {
    flex-direction: column; /* Passer à une disposition en colonnes */
    gap: 2rem;
    text-align: center; /* Centrer le texte */
  }

  /* ------------------------ MAISON-PERROQUET ------------------------ */

  .maison-perroquet {
    width: 100%; /* Utiliser la largeur de l'écran */
    text-align: center; /* Centrer le contenu */
  }

  .maison-perroquet .birdHouse,
  .maison-perroquet .perroquet {
    position: static; /* Supprimer la position absolue */
    width: auto; /* Ajuster la largeur */
    margin: 0 auto; /* Centrer horizontalement */
  }

  /* ------------------------ROADMAP ------------------------ */

  .roadmap {
    flex-direction: column;
    max-width: 100%;
    margin-block: 5em;
    gap: 2rem;
  }

  .roadmap-item {
    width: 90%;
    margin-inline: 0rem;
    margin: auto;
  }

  .roadmap-item div:nth-child(1) {
    max-width: 50%;
  }

  .roadmap-content p {
    font-size: 16px;
    font-weight: bold;
  }

  .roadmap-content span {
    display: inline-block;
    background-color: #007bff;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    margin-top: 10px;
  }

  .roadmap-item:hover .roadmap-content span {
    background-color: #0056b3;
  }
}
