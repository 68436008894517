.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Assombrit l'arrière-plan */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    pointer-events: auto;
    padding: 10px; /* Pour éviter que le popup colle aux bords sur mobile */
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    max-width: 90%; /* Évite que le popup dépasse de l'écran */
    width: 400px; /* Taille par défaut sur desktop */
    text-align: center;
}

/* Bouton de fermeture */
.modal-content button {
    margin-top: 10px;
    padding: 10px 15px;
    border: none;
    background: #ff4d4d;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.modal-content button:hover {
    background: #ff3333;
}



