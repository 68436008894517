.btn-navigation {
  padding-top: 5%;
  display: flex;

  gap: 2rem;
}

.btn-navigation > button {
  width: 10vw;
}

/* ------------- STEPS ---------------- */
.steps {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  margin-bottom: 3rem;
}

.steps li {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: var(--neutral-neutral-300, #555);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.dots-steps {
  display: flex;
  width: 36px;
  height: 36px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: #808080;
  color: var(--White, #fff);
  text-align: center;

  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.dots-steps-active {
  background: linear-gradient(159deg, #f40000 13.89%, #f75000 86.11%);
}
.dots-steps-done {
  background: #4ade80;
}

.programmation-visite-error-message {
  background-color: #f40000;
  color: white;
  padding: 2% 5%;
  margin-top: 5%;
  border-radius: 15px;
}

@media screen and (max-width: 768px) {
  /* .steps-hidden {
    display: none !important;
    background-color: #4ade80;
  } */

  .steps {
    display: flex;
    gap: 12px;
  }
  .steps li {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    font-size: 10px;
  }

  .btn-navigation > button {
    width: auto;
  }
  .btn-navigation {
    padding-top: 10%;
    justify-content: space-between;
    gap: 0;
  }
}
